.landing-page.landing-page-full {
  .landing-page-header {
    .sj-header{
      background-color: transparent;
      height: auto;
      padding: 0;

      .logo {
        margin: 0;
        height: 120px;
      }
    }
  }

  .landing-page-content {

    .item-title {
      margin: 12px 0 3px;
    }

    .item-body {
      margin-left: 0;
    }

    .content-right {
      .registration .content {
        margin: 0;
        padding-bottom: 0;

        .hint-text {
          font-size: 16px;
        }

        .sub-title {
          font-size: 16px;
          margin-bottom: 0;
          line-height: 18px;
        }
      }

      .footer {
        background-color: transparent;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        position: relative;
        right: 0;

        button.button {
          font-size: 18px;
          width: 100%;
        }
  }

}
}
}
