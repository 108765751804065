@import '../../resources/variables';

.landing-page-2 {
  align-items: center;
  background-image: url('/images/assembly-line-worker.jpg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: cover;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: center;

  .content-container {
    background-color: rgba(255,255,255,.85);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $max-width;
    width: 100%;
  }

  .logo-container {
    background: url('/images/swipejobs_logo_text.png') center no-repeat;
    display:block;

    background-size: contain;
    width: 100%;
    height: 60px;
    margin: 30px 0 0;
  }

  .text-container {
    padding: 0 20px;
  }

  .action-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .register-button {
      text-decoration: none;
    }

    button.button {
      width: 100%;
    }

    .find-out-more {
      margin: 0 auto 25px;
      padding: 10px 20px;
    }
  }
}
