@import '/resources/colors';
@import '/resources/variables';

html, body, #root, .App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 320px;
  position: relative;
}

body {
  font-family: $main-font, sans-serif;

  .page-container {
    flex: 1;
    height: 100%;
    overflow-y: auto;

     & > div {
       height: 100%;
     }

    .standard {
      box-sizing: border-box;
      padding: 14px;
    }
  }

  .outlined-basic input {
    font-size: 15px;
    line-height: 15px;
    padding: 10px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }

  .seperator {
    margin: 50px;
  }

  button.button {
    background-color: $button-bg-color;
    color: $button-text-color;

    &:active {
      background-color: $button-active-bg-color
    }

    &.Mui-disabled {
      background-color: $button-disabled-bg-color;
    }

    .MuiButton-label {
      text-transform: $main-button-transform;
    }

    &.MuiButton-root:hover {
      background-color: $button-bg-color;
    }
  }

  .disclaimer {
    font-size: 16px;
  }

  .footer {
    background-color: white;
    bottom: 0;
    left: 14px;
    margin: 0 auto;
    max-width: $max-width;
    padding: 14px 0;
    position: absolute;
    right: 14px;

    button.button {
      width: 100%;
    }
  }
}

.MuiDialog-paper.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 100px);
}

.MuiTypography-root.MuiDialogContentText-root {
  padding: 10px 0;
}

.MuiDialog-root.privacy-policy-modal .MuiDialog-paper.MuiDialog-paperScrollPaper {
  min-width: 240px;
  max-height: 80%;
  max-width: 90%;
  width: 700px;
}
