@import '../../resources/colors';
@import '../../resources/variables';

$arrowSize: 32px;

.sj-header {
  background-color: $primary-color;
  height: 38px;
  padding: 1px 10px 10px 10px;
  position: relative;

  .back {
    background-image: url('/images/arrow-back.png');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
    height: $arrowSize;
    position: absolute;
    width: $arrowSize;
  }

  .logo {
    margin: auto;
    height: 50px;
    display: block;
  }
}
