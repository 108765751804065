@import '../../resources/colors';
@import '../../resources/variables';

$inputBackground: rgba(255,255,255,.5);

.landing-page-5.landing-page-full {
  background-color: $primary-color;
  height: 390px;

  .landing-page-header {
    position: relative;
    z-index: 1;

    .sj-header {
      background-color: transparent;
      height: auto;
      padding: 0;
      margin: 0 0 0 7%;
      flex-direction: column;
      align-items: start;

      .logo {
        height: 70px;
        margin: 0 0 50px 0;
      }
    }
  }

  .landing-page-content {
    background-color: transparent;
    border-radius: 10px;
    color: $primary-title-color;
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    line-height: 26px;
    margin: 10px auto 30px;
    padding: 20px 30px;
    position: relative;
    width: 80%;
    max-width: 1100px;
    z-index: 1;

    .big-title {
      font-size: 54px;
      line-height: normal;
      padding-bottom: 8%;
      color: white;

      .no-wrap-title {
        white-space: nowrap;
      }

      & div:first-of-type {
        color: black;
      }
    }

    .content-left {
      flex: 0 0 54%;
      margin-right: 45px;
      max-width: 920px;
    }

    .content-right {
      flex: 0 0 40%;
      background-color: $secondary-background-color;
      padding: 3%;
      border-radius: 10px;
      height: 100%;
      max-width: 500px;

      .registration .content {
        margin: 0;
        padding-bottom: 0;

        .hint-text {
          font-size: 16px;
        }

        .sub-title {
          font-size: 16px;
          margin-bottom: 0;
          line-height: 18px;
          font-weight: 500;
        }
      }

      .footer {
        background-color: transparent;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        position: relative;
        right: 0;
        width: 35%;

        .MuiButtonBase-root {
          font-size: 18px;
          padding: 9%;
        }
      }
    }

    input {
      background-color: $inputBackground;
    }

    .section {
      margin-bottom: 20px;
    }

    .title {
      color: $primary-color;
      font-weight: 500;
      font-size: 32px;
      line-height: normal;
      margin-bottom: 20px;
    }

    .item-title {
      margin: 20px 0 10px;
      color: $subtitle-text-color;
      font-weight: 500;
      font-size: 24px;

    }
    .item-body {
      margin-left: 0;
    }

    .registration {
      .sub-title {
        margin-bottom: 10px;
      }

      .suggestion-contents {
        background-color: $inputBackground;
      }

      .location-search-input {
        background-color: transparent;
      }

      .privacy-policy-wrapper {
        font-size: 16px;
        width: 90%;
      }

      button.button {
        margin-top: 10px;
        width: 90%;

        &:disabled {
          background-color: $button-disabled-bg-color;
        }
      }
    }

    .contact-us {
      margin-top: 30px;
    }
  }

  .down-button{
    bottom: 30px;
    color: $primary-color;
    display: none;
    position: absolute;
    right: 30px;
    z-index: 1;

    &:active {
      color: $secondary-color;
    }

    .foreground {
      position: absolute;
    }

    .background svg {
      color: white;
    }

    svg {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1215px) {
    .landing-page {
      padding: 0 10px;
    }

    .landing-page-content {
      box-sizing: border-box;
      flex-direction: column;
      font-size: 16px;
      margin-top: 0;
      width: calc(100% - 20px);

      .registration .content,
      .footer {
        max-width: none;
      }
    }

    .down-button {
      display: block;
    }
  }

  @media screen and (max-width: 900px) {
    .landing-page-header .sj-header.centred {
      .header-title {
        font-size: 45px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .landing-page-header .sj-header.centred {
      position: relative;

      .header-title {
        font-size: 30px;
        left: 0;
        padding-left: 20px;
        position: absolute;
        text-align: center;
        top: 80px;
        width: 100%;
      }
    }

    .landing-page-content {
      margin-top: 10px;

      .big-title {
        font-size: 52px;
      }

      .item-body {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 570px) {
    height: 350px;

    .landing-page-content {
      .big-title {
        font-size: 48px;
      }
    }

    .landing-page-header .sj-header .logo {
          margin: 0 0 30px 0;
    }

  }
  @media screen and (max-width: 530px) {
    height: 240px;

    .landing-page-content {
      .big-title {
        font-size: 32px;
      }
    }

    .landing-page-header .sj-header .logo {
      margin: 0 0 10px 0;
    }

  }
  @media screen and (max-width: 380px) {
    height: 220px;

    .landing-page-content {
      .big-title {
        font-size: 24px;
      }
    }
  }
}